import checkPostalCode from './checkPostalCode';
import $ from 'jquery';
import scrollToElement from './scrollToElement';

/*
 * Render errors to screen
 *
 */
function generalSearchErrors () {
  let container = document.querySelector('#general-search-tab');
  
  let postal = container.querySelector('input.cpso-postal').value;
  let specialist = container.querySelector('input.cpso-specialist').checked;
  let specialization = container.querySelector('select.cpso-specialization');
  specialization = specialization.options[specialization.selectedIndex].value;
  let active = container.querySelector('input.cpso-regactive').checked;
  let inactive = container.querySelector('input.cpso-reginactive').checked;

  let btnAdvToggle = document.querySelector('div.toggle-advanced');
  let advSearchBlock = document.querySelector('.advanced-search');
  let advancedState = document.querySelector('#advancedState');

  let concernsBlock = document.querySelector('.doctor-concerns #concerns-checklist');
  let cpsoNumber = container.querySelector('input.cpso-number').value;

  let errorWrapper = container.querySelector('.global-error-msg ul');
  let errorMsgs = window.errorMsgs;
  let errorText = '';
  let hasError = false;

  /*
   * CPSO Number
   */
  let cpsoDiv = container.querySelector('#form-cpsonumber');
  let cpsoDivError = cpsoDiv.querySelector('.error-msg');
  let cpsoError = false;
  if (cpsoNumber.length > 0) {
    if (cpsoNumber.length < 5) {
      errorText += `<li>${errorMsgs.cpsonumber}</li>`;
      cpsoDiv.classList.add('has-error');
      cpsoDivError.innerHTML = `${errorMsgs.cpsonumber.replace(/<\/?[^>]+(>|$)/g, "")}`;
      cpsoDiv.querySelector('input, select').setAttribute('aria-describedby', cpsoDivError.getAttribute('id'));
      cpsoError = true;
      hasError = true;
    }
  }
  if (!cpsoError) { // Clear postal errors
    cpsoDiv.classList.remove('has-error');
    cpsoDiv.querySelector('input, select').removeAttribute('aria-describedby');
    cpsoDivError.innerHTML = '';
  }

  /*
   * Postal Code
   */
  let postalDiv = container.querySelector('#form-postalcode');
  let postalDivError = postalDiv.querySelector('.error-msg');
  let postalError = false;
  if (postal.length > 0) {
    let validPostalCode = checkPostalCode(postal);
    if (!validPostalCode) {
      errorText += `<li>${errorMsgs.postalcode}</li>`;
      postalDiv.classList.add('has-error');
      postalDivError.innerHTML = `${errorMsgs.postalcode.replace(/<\/?[^>]+(>|$)/g, "")}`;
      postalDiv.querySelector('input, select').setAttribute('aria-describedby', postalDivError.getAttribute('id'));
      postalError = true;
      hasError = true;
    }
  }
  if (!postalError) { // Clear postal errors
    postalDiv.classList.remove('has-error');
    postalDiv.querySelector('input, select').removeAttribute('aria-describedby');
    postalDivError.innerHTML = '';
  }

  /*
   * Specialization
   */
  let specialistDiv = container.querySelector('#cpsoSpecialzation');
  let specialistDivError = specialistDiv.querySelector('.error-msg');
  let specialistError = false;
  if (specialist) {
    if (specialization === '') {
      errorText += `<li>${errorMsgs.specialization}</li>`;
      specialistDiv.classList.add('has-error');
      specialistDivError.innerHTML = `${errorMsgs.specialization.replace(/<\/?[^>]+(>|$)/g, "")}`;
      specialistDiv.querySelector('input, select').setAttribute('aria-describedby', specialistDivError.getAttribute('id'));
      specialistError = true;
      hasError = true;
      // Open advanced search
      $(advSearchBlock).show();
      btnAdvToggle.classList.add('active');
      advancedState.value = 'open';
    }
  }
  if (!specialistError) { // Clear postal errors
    specialistDiv.classList.remove('has-error');
    specialistDiv.querySelector('input, select').removeAttribute('aria-describedby');
    specialistDivError.innerHTML = '';
  }

  /*
   * Registration Status
   */
  let regstatusDiv = container.querySelector('#form-regstatus');
  let regstatusDivError = regstatusDiv.querySelector('.error-msg');
  let regstatusError = false;
  if (!active && !inactive) {
    errorText += `<li>${errorMsgs.regstatus}</li>`;
    regstatusDiv.classList.add('has-error');
    regstatusDivError.innerHTML = `${errorMsgs.regstatus.replace(/<\/?[^>]+(>|$)/g, "")}`;
    regstatusDiv.querySelector('fieldset').setAttribute('aria-describedby', regstatusDivError.getAttribute('id'));
    regstatusError = true;
    hasError = true;
    // Open advanced search
    $(advSearchBlock).show();
    btnAdvToggle.classList.add('active');
    advancedState.value = 'open';
  }
  if (!regstatusError) { // Clear postal errors
    regstatusDiv.classList.remove('has-error');
    regstatusDiv.querySelector('fieldset').removeAttribute('aria-describedby');
    regstatusDivError.innerHTML = '';
  }

  /*
   * Concerns
   */
  let btnAdvToggleConcerns = document.querySelector('.doctor-concerns button.toggle-switch');
  let concernsDiv = container.querySelector('#form-concerns');
  let concernsDivError = concernsBlock.querySelector('.error-msg');
  let concernsChecked = false;
  // Loop all concern checkboxes, to prevent unchecking all boxes
  [...concernsBlock.querySelectorAll('input')].forEach(function (concern) {
    if (concern.checked) {
      concernsChecked = true;
    }
  });

  if (!concernsChecked && btnAdvToggleConcerns.classList.contains('active')) {
    errorText += `<li>${errorMsgs.concerns}</li>`;
    concernsBlock.classList.add('has-error');
    concernsDivError.innerHTML = `${errorMsgs.concerns.replace(/<\/?[^>]+(>|$)/g, "")}`;
    concernsDiv.querySelector('fieldset').setAttribute('aria-describedby', regstatusDivError.getAttribute('id'));
    hasError = true;
  } else {
    concernsBlock.classList.remove('has-error');
    concernsDiv.querySelector('fieldset').removeAttribute('aria-describedby');
    concernsDivError.innerHTML = '';
  }

  /*
   * Error Found
   */

  if (hasError) {
    errorText = `<li class="intro">${errorMsgs.intro}</li>` + errorText;
    errorWrapper.innerHTML = errorText; // Add error msg to screen
    scrollToElement('#content', container, false); // Send user to top of form
    $('.global-error-msg', container).slideDown(300);
  } else {
    errorText = '';
    $('.global-error-msg', container).slideUp(250, function () {
      errorWrapper.innerHTML = '';
    });
  }
}
export default generalSearchErrors;
