const formToggle = (() => {
    const communicationMethod = Array.from(document.querySelectorAll('input[name="RegCommunicationMethod"]'));
    const leaveMessage = document.querySelector('.c-form-field-voice-message');
    const patientStatus = Array.from(document.querySelectorAll('input[name="RegIsPatient"]'));
    const notPatient = document.querySelector('.c-form-field-isNotPatient');
    const dob = document.querySelector('.c-form-field-dob');
    const RegAddrTelephone = document.querySelector('[for="RegAddrTelephone"]');
    const RegEmail = document.querySelector('[for="RegEmail"]');

   if (communicationMethod.length > 0) {
       const RegEmailText = RegEmail.innerText;
       const RegAddrTelephoneText = RegAddrTelephone.innerText;
       communicationMethod.forEach(radioBtn => {
           radioBtn.addEventListener('change', (e) => {
               e.preventDefault()
               const newRegEmail = RegEmail.innerText.replace('*', '');
               const newRegAddrTelephone = RegAddrTelephone.innerText.replace('*', '');
               const originalRegEmail = RegEmail.innerText.replace(RegEmailText, RegEmailText);
               const originalRegAddrTelephone = RegAddrTelephone.innerText.replace(RegAddrTelephoneText, RegAddrTelephoneText + ' *');

               if (radioBtn.checked == true && radioBtn.value === '904710000') {
                   leaveMessage.classList.remove('hide');
                   RegEmail.innerText = newRegEmail;
                   RegAddrTelephone.innerText = originalRegAddrTelephone;
               } else if (radioBtn.checked == true && radioBtn.value === '904710001') {
                   leaveMessage.classList.add('hide');
                   RegAddrTelephone.innerHTML = newRegAddrTelephone;
                   RegEmail.innerText = originalRegEmail + ' *';
               } else if (radioBtn.checked == true && radioBtn.value === '904710002') {
                   leaveMessage.classList.add('hide');
                   RegEmail.innerText = newRegEmail;
                   RegAddrTelephone.innerHTML = newRegAddrTelephone;
               }
           })
       })
   }

   if (patientStatus) {
       patientStatus.forEach(radioButton => {
           radioButton.addEventListener('change', (e) => {
               if (radioButton.checked == true && radioButton.value === 'yes') {
                   notPatient.classList.add('hide')
                   dob.classList.remove('hide')
               } else {
                   notPatient.classList.remove('hide');
                   dob.classList.add('hide')
               }
           })
       })
   }

})();

export default formToggle;