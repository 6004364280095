import './formValidation';
import './formFocus';
import './formAttachment';
import './formAddElements';
import './formToggle';
import './formSetDate';
import validateFields from "./formValidation";
import AttachFields from './formAttachment';

const Forms = (() => {
    const forms = Array.from(document.querySelectorAll('[data-form]'));
    const toggleServerError = (serverError, isShow) => {
        if (isShow) {
            serverError.setAttribute('aria-hidden', false);
            serverError.focus();
        } else {
            serverError.setAttribute('aria-hidden', true);
        }
    };

    const toggleOverlay = (overLay, isActive) => {
        const body = document.body;
        overLay.setAttribute('aria-hidden', !isActive);

        if (isActive) {
            overLay.classList.add('show-overlay');            
            overLay.focus();
            body.classList.add('no-scroll');
            body.inert = true;
        } else {
            overLay.classList.remove('show-overlay');
            overLay.blur();
            body.classList.remove('no-scroll');
            body.inert = false;
        }
    };

    return {
        init: () => {
            if (forms.length > 0) {
                forms.forEach((formWrapper) => {
                    const form = formWrapper.querySelector('.c-form'),
                        submit = formWrapper.querySelector('.c-form-submit'),
                        attachments = Array.from(formWrapper.querySelectorAll('input[type=file]')),
                        serverError = formWrapper.querySelector('.c-form-error-server'),
                        overLay = document.querySelector('.o-overlay'),
                        captchaKey = document.querySelector('.CPSOCaptchaSiteKey'),
                        captchaResponse = document.querySelector('.GoogleCaptchaUserResponse');
					if(form) {
						submit.onclick = (e) => {
							e.preventDefault();
							toggleServerError(serverError, false);

							grecaptcha.ready(function() {
								grecaptcha.execute(captchaKey.value, {action: 'submit'}).then(function(token) {
									captchaResponse.value = token;
						   
									if (overLay) {
										toggleOverlay(overLay, true);
									}

									validateFields
										.init(form.id, form.action)
										.then((response) => {
											if (response.data.status === 'Success') {
												if (response.data.RedirectToUrl) {
													window.location = response.data.RedirectToUrl;
												}

												if (formWrapper.classList.contains('is-full')) {
													formWrapper.setAttribute('aria-hidden', 'true');
													window.scrollTo(0,0);
												}
											} else {
												toggleServerError(serverError, true);
											}

											if (overLay) {
												toggleOverlay(overLay, false);
											}
										})
										.catch((error) => {
											console.log('Error', error)

											if (overLay) {
												toggleOverlay(overLay, false);
											}
											let response = error.response;

											// Validation error code
											if (response.status === 400) {
												return;
											}

											toggleServerError(serverError, true);
										});
									});
								});
						};

						if (attachments.length >= 0) {
							attachments.forEach(attachment => {
								AttachFields.init(attachment, submit);
							})
						}
					}
                })
            }
        }
    }
})();

export default Forms;
