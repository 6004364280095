// Validates if both CPSO Number and Last name are empty
function validateQuickSearch () {
  let container = document.querySelector('#quick-search-tab');
  let cpsoNumber = container.querySelector('input.cpso-number').value;
  let lastname = container.querySelector('input.cpso-lastname').value;

  if ((cpsoNumber.length < 5) && (lastname.length < 1)) { // Number and Lastname are empty
    return true;
  } else {
    return false;
  }
};

export default validateQuickSearch;
