import $ from 'jquery';

const CPSOCookie = 'cpso_alert';
const CMS = document.querySelector('.EditorWidgetZone');

// Position Alert widget
function positionAlert (ww, desktopW) {

  if (!CMS){
	if (ww < desktopW ) {
		// Mobile
		$(cpsoAlert).prependTo($('#siteheader'));
		$("#content").addClass("alert_message_shown");

	 } else {
		// Desktop
		$(cpsoAlert).prependTo($('#siteheader'));
		$("#content").addClass("alert_message_shown");
	 }
  }
}

// Click event (Close alert)
function CPSOAlertClick (e) {
  e.preventDefault();
  $(cpsoAlert).slideUp();

  sessionStorage.setItem(CPSOCookie, 'true');
  $("#content").removeClass("alert_message_shown");

  // console.log(sessionStorage.getItem(CPSOCookie));
}

// Alerts displayed on homepage
let cpsoAlert = document.querySelector('.cpso-alert');
let cpsoAlertBtn = document.querySelector('.cpso-alert a.closeButton');

setTimeout(function() {

	if (!sessionStorage.getItem(CPSOCookie) && (typeof (cpsoAlertBtn) !== 'undefined' && cpsoAlertBtn != null)){
		//if (typeof (cpsoAlert) !== 'undefined' && cpsoAlert != null) {
		  let ww = window.innerWidth;
		  let desktopW = 1170;

		  // Click event

		  cpsoAlertBtn.addEventListener('click', CPSOAlertClick);

		  // Resize event
		  $(window).resize(function () {
			 ww = window.innerWidth;
			 positionAlert(ww, desktopW);
		  });

		  positionAlert(ww, desktopW);

		  // Check sessionStorage support
		  if (window.sessionStorage) {
			 // Check sessionStorage on page load.
			 if (!sessionStorage.getItem(CPSOCookie)) {
				$(cpsoAlert).slideDown(200, function () {
				  cpsoAlert.classList.add('active');
				  $("#content").addClass("alert_message_shown");
				});
			 }
		  }
		}
},2)


