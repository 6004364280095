const formSetDate = (() => {
    const inputs = Array.from(document.querySelectorAll('[type="date"]'));
    inputs.forEach(input => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if(dd < 10){
            dd = '0' + dd;
        }
        if(mm < 10){
            mm ='0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        input.setAttribute('max', today);
    })
})();

export default formSetDate;