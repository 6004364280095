//including smooth scroll
import $ from 'jquery';


//loop through all the h2s on doc detials page
const clinicList = document.querySelector('.clinic-list');


if (clinicList) {
    const clinicToggles = document.querySelectorAll('.clinic-list-toggle input[type="radio"]');
    const clinicPanels = document.querySelectorAll('.clinic-list-panel');
    const scrollTopBtns = document.querySelectorAll('.scroll-to-top');

    [...scrollTopBtns].forEach((btn) => {
        btn.addEventListener('click', () => {
            const content = document.getElementById('content');
            const headerHeight = $("header#siteheader").height();
            const scrollToPosition = $(content).offset().top - headerHeight;
            
            content.setAttribute('tabindex', '-1');

            $('html, body').animate({
                scrollTop: scrollToPosition
              }, 600, function() {
                    content.focus();
              });
        })
    });
    [...clinicPanels].filter((panel) => {
        const list = panel.querySelector('ul');
        const listItems = list ? list.querySelectorAll('li') : 0;
        if (!list || listItems.length <= 0) {
            const toggle = document.getElementById(`${panel.id}-btn`)
            toggle.setAttribute('disabled', 'true')
            panel.parentNode.removeChild(panel);
            return panel
        } 
    });
    [...clinicToggles].forEach((toggle) => {
        toggle.addEventListener('change', () => {
            if (toggle.value === "tabAll") {
                [...clinicPanels].forEach((panel) => {
                    panel.style.display = 'block';
                    panel.removeAttribute('aria-hidden');
                })
            } else {
                const panel = document.getElementById(toggle.value);
                const otherPanels = [...clinicPanels].filter(panel => panel.id != toggle.value);

                panel.setAttribute('aria-hidden', 'false');
                panel.style.display = "block";

                [...otherPanels].forEach((panel) => {
                    panel.style.display = 'none';
                    panel.setAttribute('aria-hidden', 'true');
                })
            }
        })
    })
}