import scrollToElement from './scrollToElement';
import generalSearchErrors from './generalSearchErrors';
import quickSearchErrors from './quickSearchErrors';

function resetSearchForm (searchtype) {
  let fields = document.querySelectorAll('#' + searchtype + '-search-tab .cpso-search');

  // Loop over fields and reset their values
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    let type = field.dataset.formtype;
    let defaultvalue = field.dataset.defaultvalue;

    if (type === 'text' || type === 'select') {
      field.value = defaultvalue;
    }
    if (type === 'radio' || type === 'checkbox' || type === 'chk') {
      if (defaultvalue === 'true') { field.checked = true; } else { field.checked = false; }
    }
  }
  generalSearchErrors();
  quickSearchErrors(true);
  scrollToElement('#content');
  var heading = $('.search-panel').not(':hidden').find('h2').first();
  $(heading).focus();
}
export default resetSearchForm;
