// 11/27/2019 - I don't know what this is for (looks like for array stuff), Babel has been added, this might not be needed anymore - leaving for now

/* Array .includes Pollyfill for IE. package from:
https://www.npmjs.com/package/polyfill-array-includes
*/
import 'polyfill-array-includes';

var isIE = /*@cc_on!@*/false || !!document.documentMode;

if (isIE) {
	// Production steps of ECMA-262, Edition 6, 22.1.2.1
	console.log('this is IE11')
if (!Array.from) {
	Array.from = (function () {
	  var toStr = Object.prototype.toString;
	  var isCallable = function (fn) {
		 return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
	  };
	  var toInteger = function (value) {
		 var number = Number(value);
		 if (isNaN(number)) { return 0; }
		 if (number === 0 || !isFinite(number)) { return number; }
		 return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
	  };
	  var maxSafeInteger = Math.pow(2, 53) - 1;
	  var toLength = function (value) {
		 var len = toInteger(value);
		 return Math.min(Math.max(len, 0), maxSafeInteger);
	  };

	  // The length property of the from method is 1.
	  return function from(arrayLike/*, mapFn, thisArg */) {
		 // 1. Let C be the this value.
		 var C = this;

		 // 2. Let items be ToObject(arrayLike).
		 var items = Object(arrayLike);

		 // 3. ReturnIfAbrupt(items).
		 if (arrayLike == null) {
			throw new TypeError('Array.from requires an array-like object - not null or undefined');
		 }

		 // 4. If mapfn is undefined, then let mapping be false.
		 var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
		 var T;
		 if (typeof mapFn !== 'undefined') {
			// 5. else
			// 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
			if (!isCallable(mapFn)) {
			  throw new TypeError('Array.from: when provided, the second argument must be a function');
			}

			// 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
			if (arguments.length > 2) {
			  T = arguments[2];
			}
		 }

		 // 10. Let lenValue be Get(items, "length").
		 // 11. Let len be ToLength(lenValue).
		 var len = toLength(items.length);

		 // 13. If IsConstructor(C) is true, then
		 // 13. a. Let A be the result of calling the [[Construct]] internal method
		 // of C with an argument list containing the single item len.
		 // 14. a. Else, Let A be ArrayCreate(len).
		 var A = isCallable(C) ? Object(new C(len)) : new Array(len);

		 // 16. Let k be 0.
		 var k = 0;
		 // 17. Repeat, while k < len… (also steps a - h)
		 var kValue;
		 while (k < len) {
			kValue = items[k];
			if (mapFn) {
			  A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
			} else {
			  A[k] = kValue;
			}
			k += 1;
		 }
		 // 18. Let putStatus be Put(A, "length", len, true).
		 A.length = len;
		 // 20. Return A.
		 return A;
	  };
	}());
 }

 // https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
	  value: function(valueToFind, fromIndex) {

		 if (this == null) {
			throw new TypeError('"this" is null or not defined');
		 }

		 // 1. Let O be ? ToObject(this value).
		 var o = Object(this);

		 // 2. Let len be ? ToLength(? Get(O, "length")).
		 var len = o.length >>> 0;

		 // 3. If len is 0, return false.
		 if (len === 0) {
			return false;
		 }

		 // 4. Let n be ? ToInteger(fromIndex).
		 //    (If fromIndex is undefined, this step produces the value 0.)
		 var n = fromIndex | 0;

		 // 5. If n ≥ 0, then
		 //  a. Let k be n.
		 // 6. Else n < 0,
		 //  a. Let k be len + n.
		 //  b. If k < 0, let k be 0.
		 var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

		 function sameValueZero(x, y) {
			return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
		 }

		 // 7. Repeat, while k < len
		 while (k < len) {
			// a. Let elementK be the result of ? Get(O, ! ToString(k)).
			// b. If SameValueZero(valueToFind, elementK) is true, return true.
			if (sameValueZero(o[k], valueToFind)) {
			  return true;
			}
			// c. Increase k by 1.
			k++;
		 }

		 // 8. Return false
		 return false;
	  }
	});
 }

// https://tc39.github.io/ecma262/#sec-array.prototype.find
	if (!Array.prototype.find) {
		Object.defineProperty(Array.prototype, 'find', {
			value: function(predicate) {
				// 1. Let O be ? ToObject(this value).
				if (this == null) {
					throw new TypeError('"this" is null or not defined');
				}

				var o = Object(this);

				// 2. Let len be ? ToLength(? Get(O, "length")).
				var len = o.length >>> 0;

				// 3. If IsCallable(predicate) is false, throw a TypeError exception.
				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function');
				}

				// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
				var thisArg = arguments[1];

				// 5. Let k be 0.
				var k = 0;

				// 6. Repeat, while k < len
				while (k < len) {
					// a. Let Pk be ! ToString(k).
					// b. Let kValue be ? Get(O, Pk).
					// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
					// d. If testResult is true, return kValue.
					var kValue = o[k];
					if (predicate.call(thisArg, kValue, k, o)) {
						return kValue;
					}
					// e. Increase k by 1.
					k++;
				}

				// 7. Return undefined.
				return undefined;
			}
		});
	}


 if (!String.prototype.includes) {
	Object.defineProperty(String.prototype, 'includes', {
	  value: function(search, start) {
		 if (typeof start !== 'number') {
			start = 0
		 }

		 if (start + search.length > this.length) {
			return false
		 } else {
			return this.indexOf(search, start) !== -1
		 }
	  }
	})
 }

	/* elem.closest() polyfill to be used with IE. Referenced from:
   https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
   */
	if (window.Element && !Element.prototype.closest) {
		Element.prototype.closest =
			function(s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i,
					el = this;
				do {
					i = matches.length;
					while (--i >= 0 && matches.item(i) !== el) {};
				} while ((i < 0) && (el = el.parentElement));
				return el;
			};
	}
	/* Object.entries polyfill to be used with IE. Referenced from:
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
*/
	if (!Object.entries)
		Object.entries = function( obj ){
			var ownProps = Object.keys( obj ),
				i = ownProps.length,
				resArray = new Array(i); // preallocate the Array
			while (i--)
				resArray[i] = [ownProps[i], obj[ownProps[i]]];

			return resArray;
		};
	/* Object.assign polyfill to be used with IE. Referenced from:
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill
*/
	if (typeof Object.assign != 'function') {
// Must be writable: true, enumerable: false, configurable: true
		Object.defineProperty(Object, "assign", {
			value: function assign(target, varArgs) { // .length of function is 2
				'use strict';
				if (target == null) { // TypeError if undefined or null
					throw new TypeError('Cannot convert undefined or null to object');
				}

				var to = Object(target);

				for (var index = 1; index < arguments.length; index++) {
					var nextSource = arguments[index];

					if (nextSource != null) { // Skip over if undefined or null
						for (var nextKey in nextSource) {
							// Avoid bugs when hasOwnProperty is shadowed
							if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
				}
				return to;
			},
			writable: true,
			configurable: true
		});
	}

	/* .matches Polyfill for IE. Referenced from:
https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
*/

	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector ||
			Element.prototype.webkitMatchesSelector;
	}

}
