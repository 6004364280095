import $ from 'jquery';
import validateQuickSearch from './search/validateQuickSearch';
import validateGeneralSearch from './search/validateGeneralSearch';
import resetSearchForm from './search/resetSearchForm';
import generalSearchErrors from './search/generalSearchErrors';
import quickSearchErrors from './search/quickSearchErrors';
import scrollToElement from './search/scrollToElement';

let ConcernsState = document.querySelector('#ConcernsState');
let advancedState = document.querySelector('#advancedState');

// Advanced Search block
let advSearchBlock = document.querySelector('.advanced-search');
let btnAdvToggle = document.querySelector('button.toggle-advanced');
// if (btnAdvToggle) {
//   btnAdvToggle.addEventListener('click', toggleAdvanced);
//   btnAdvToggle.addEventListener('keyup', function (e) {
//     let advKey = e.keyCode ? e.keyCode : e.which;
//     if (advKey === 32) { // Spacebar
//       toggleAdvanced();
//     }
//   });
// }

// Toggle active class of advanced search

// function toggleAdvanced () {
//   if (btnAdvToggle.classList.contains('active')) {
//     closeAdvanced();
//   } else {
//     openAdvanced();
//   }
//   return false;
// }

// Open Advanced Search
// function closeAdvanced () {
//   $(advSearchBlock).slideUp(200, function () {
//     btnAdvToggle.classList.remove('active');
//     btnAdvToggle.setAttribute('aria-expanded', 'false');
//     this.setAttribute('aria-hidden', 'true');

//     advancedState.value = 'closed';
//   });
// }

// Close Advanced Search
// function openAdvanced () {
//   $(advSearchBlock).slideDown(200, function () {
//     btnAdvToggle.classList.add('active');
//     btnAdvToggle.setAttribute('aria-expanded', 'true');
//     this.setAttribute('aria-hidden', 'false');

//     advancedState.value = 'open';
//   });
// }

// Doctor Concerns block
let concernsBlock = document.querySelector('.doctor-concerns .concerns');
let btnToggleConcerns = document.querySelector('.doctor-concerns button.toggle-switch');
let btnToggleWrap = document.querySelector('.doctor-concerns div.toggle-switch-inner ');
if (btnToggleConcerns) {
  btnToggleConcerns.addEventListener('click', toggleConcerns);
  btnToggleConcerns.addEventListener('keyup', function (e) {
    let concernsKey = e.keyCode ? e.keyCode : e.which;

    if (concernsKey === 39) { // Right Arr
      openConcerns();
    }
    if (concernsKey === 37) { // Left Arr
      closeConcerns();
    }
  });
}

// Toggle active class of Doctor Concerns
function toggleConcerns () {
  if (btnToggleConcerns.classList.contains('active')) {
    closeConcerns();
  } else {
    openConcerns();
  }
  return false;
}

// Close Doctor Concerns
function closeConcerns () {
  btnToggleConcerns.classList.remove('active');
  btnToggleWrap.classList.remove('active');
  btnToggleConcerns.setAttribute('aria-expanded', 'false');
  $(concernsBlock).delay(150).slideUp(200);
  $(concernsBlock).attr('aria-hidden', 'true');
  ConcernsState.value = 'closed';
}

// Open Doctor Concerns
function openConcerns () {
  btnToggleConcerns.classList.add('active');
  btnToggleConcerns.setAttribute('aria-expanded', 'true');
  btnToggleWrap.classList.add('active');
  $(concernsBlock).delay(150).slideDown(200);
  $(concernsBlock).attr('aria-hidden', 'false');
  ConcernsState.value = 'open';
}

// Form Submit
function docSearchSubmit (event) {
  let quickSubmitBtn = document.querySelector('#quick-search-tab input.submit');
  let generalSubmitBtn = document.querySelector('#general-search-tab input.submit');
  let searchType = document.querySelector('#searchType').value;

  // Quick Search
  if (searchType === 'quick') {
    quickSubmitBtn.classList.add('submitting');
    let quickSearchDisabled = validateQuickSearch();
    if (quickSearchDisabled) {
      quickSearchErrors(); // display search errors
      quickSubmitBtn.classList.remove('submitting');
      event.preventDefault();
    }
  }

  // General Search
  if (searchType === 'general') {
    generalSubmitBtn.classList.add('submitting');
    let generalSearchDisabled = validateGeneralSearch();
    if (generalSearchDisabled) {
      generalSearchErrors(); // display search errors
      generalSubmitBtn.classList.remove('submitting');
      event.preventDefault();
    }
  }
}

// Reset search forms
function resetSearch () {
  let searchType = document.querySelector('#searchType').value;
  resetSearchForm(searchType);
  closeConcerns();
  // closeAdvanced();
}

/*
 * Global Listeners
 *
 */
function defineListeners () {
  let quickClearBtn = document.querySelector('#quick-search-tab button.reset');
  quickClearBtn.addEventListener('click', resetSearch);

  let generalClearBtn = document.querySelector('#general-search-tab button.reset');
  generalClearBtn.addEventListener('click', resetSearch);

  // Global error msg listeners.
  $(document).on('click', '.global-error-msg li a', function (e) {
    let urlTarget = $(this).attr('href');
    scrollToElement(urlTarget, '', true);
    e.preventDefault();
  });

  // Radio/checkbox listeners for focus
  $(document).on('focus', 'input[type=checkbox], input[type=radio]', function (e) {
    var el = $(this);
    el.next('label').addClass('active');
  });
  // Radio/checkbox listeners for focus
  $(document).on('blur', 'input[type=checkbox], input[type=radio]', function (e) {
    var el = $(this);
    el.next('label').removeClass('active');
  });

  // CPSO Number/Last Name Listener
  let cpsoNumber = document.querySelector('input.cpso-number');
  let lastname = document.querySelector('input.cpso-lastname');

  // CPSO Number Listener
  let cpsoNumberUpdate = () => {
    setTimeout(function () {
      if (cpsoNumber.value !== '') {
        lastname.value = '';
      }
    }, 50);
  };

  // Lastname Listener
  let lastnameUpdate = () => {
    setTimeout(function () {
      if (lastname.value !== '') {
        cpsoNumber.value = '';
      }
    }, 50);
  };

  cpsoNumber.addEventListener('change', cpsoNumberUpdate);
  cpsoNumber.addEventListener('keyup', cpsoNumberUpdate);
  cpsoNumber.addEventListener('paste', cpsoNumberUpdate);
  lastname.addEventListener('change', lastnameUpdate);
  lastname.addEventListener('keyup', lastnameUpdate);
  lastname.addEventListener('paste', lastnameUpdate);

  // Global input listener
  let docSearchContainer = document.getElementById('doctor-search-form');
  [...docSearchContainer.querySelectorAll('input[type=text]')].forEach(function (element) {
    element.addEventListener('keypress', cpsoOnEnterPress);
  });
}

let cpsoOnEnterPress = (e) => {
  let key = e.which || e.keyCode;
  let cpsoSearchType = document.querySelector('#searchType').value;
  let quickSubmitBtn = document.querySelector('#quick-search-tab input[type=submit]');
  let generalSubmitBtn = document.querySelector('#general-search-tab input[type=submit]');
  let submitButton = '';
  if (cpsoSearchType === 'quick') {
    submitButton = quickSubmitBtn;
  } else if (cpsoSearchType === 'general') {
    submitButton = generalSubmitBtn;
  } else {
    submitButton = quickSubmitBtn;
  }
    // trigger submit btn click
  if (key === 13) { // 13 is enter
    e.preventDefault();
    submitButton.click();
  }
};

/*
 * Initiate Doc Search functions
 *
 */
let isDocSearch = document.getElementById('doctor-search-form');
if (isDocSearch) {
  defineListeners();

  if (ConcernsState.value === 'open') toggleConcerns();
  if (advancedState.value === 'open') toggleAdvanced();

  // Add form submit eventListener
  let form = document.getElementById('form');
  $(form).removeAttr('onsubmit');
  if (form.addEventListener) {
    form.addEventListener('submit', docSearchSubmit, false);
  } else if (form.attachEvent) {
    form.attachEvent('onsubmit', docSearchSubmit);
  }
}

/*
 * Fake Back Button on Search Results
 * This function sets a pushState on page load. Then when a pop state is fired, we grab the
 * href from the 'Go back to refine your search' btn.
 *
 * Client didn't like how the app was displayed after a back button click so we needed to fake it.
 *
 */
// let isDocSearchResults = document.querySelector('.doctor-search-results');
// if (isDocSearchResults) {
//   let backLink = document.querySelector('.doctor-search-header .go-back a').getAttribute('href');
//   if (typeof history.pushState === 'function') {
//     setTimeout(function () {
//       history.pushState('...', null, null);
//     }, 50);
//     window.onpopstate = function () {
//       window.location.href = backLink;
//     };
//   }
// }

/*
 * Check for errors on page load
 *
 */
let QuickGlobalError = document.querySelector('#quick-search-tab .global-error-msg');
let GeneralGlobalError = document.querySelector('#general-search-tab .global-error-msg');

if (QuickGlobalError && QuickGlobalError.style.display === "block") {
	let container = document.querySelector('#quick-search-tab');
	scrollToElement('#content', container, false);
};
if (GeneralGlobalError && GeneralGlobalError.style.display === "block") {
	let container = document.querySelector('#general-search-tab');
	scrollToElement('#content', container, false);
};

let SearchResults = document.querySelectorAll('.doctor-search-results--result');
if (SearchResults.length > 0) {
  let container = document.getElementById('content');
  container.setAttribute('tabindex', '-1');

  setTimeout(() => {
    container.focus();
  }, 200);
  
}