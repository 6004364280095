import $ from 'jquery';
import scrollToElement from './scrollToElement';

/*
 * Render errors to screen
 *
 */
function quickSearchErrors (reset = false) {
  let container = document.querySelector('#quick-search-tab');
  let cpsoNumber = container.querySelector('input.cpso-number').value;
  let lastname = container.querySelector('input.cpso-lastname').value;

  let errorWrapper = container.querySelector('.global-error-msg ul');
  let errorMsgs = window.errorMsgs;
  let errorText = '';
  let hasError = false;

  /*
   * CPSO Number and Lastname EMpty
   */
  if ((cpsoNumber.length < 1) && (lastname.length < 1)) {
    errorText += `<li>${errorMsgs.quicksearch}</li>`;
    hasError = true;
  }

  /*
   * CPSO Number is not long enough
   */
  if ((cpsoNumber.length > 0 && cpsoNumber.length < 5) && (lastname.length < 1)) {
    let cpsoDiv = container.querySelector('#form-cpsonumber2');
    let cpsoDivError = cpsoDiv.querySelector('.error-msg');
    
    errorText += `<li>${errorMsgs.cpsonumber2}</li>`;
    cpsoDiv.classList.add('has-error');
    cpsoDivError.innerHTML = `${errorMsgs.cpsonumber2.replace(/<\/?[^>]+(>|$)/g, "")}`;
    cpsoDiv.querySelector('input, select').setAttribute('aria-describedby', cpsoDivError.getAttribute('id'));
    hasError = true;

  } else {
    let cpsoDiv = container.querySelector('#form-cpsonumber2');
    let cpsoDivError = cpsoDiv.querySelector('.error-msg');
    cpsoDiv.classList.remove('has-error');
    cpsoDiv.querySelector('input, select').removeAttribute('aria-describedby');
    cpsoDivError.innerHTML = '';
  }

  /*
   * Reset form
   */
  if (reset) {
    errorText = '';
    $('.global-error-msg', container).slideUp(250, function () {
      errorWrapper.innerHTML = '';
    });
  } else {
    /*
     * Error Found
     */

    if (hasError) {
      errorText = `<li class="intro">${errorMsgs.intro}</li>` + errorText;
      errorWrapper.innerHTML = errorText; // Add error msg to screen
      scrollToElement('#content', container, false); // Send user to top of form
      $('.global-error-msg', container).slideDown(300);
    } else {
      errorText = '';
      $('.global-error-msg', container).slideUp(250, function () {
        errorWrapper.innerHTML = '';
      });
    }
  }
}
export default quickSearchErrors;
