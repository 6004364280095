const raisingConcernsContent = document.querySelector('.raising-concerns');

if (raisingConcernsContent) {
	const triggers = [...document.querySelectorAll('.raising-concerns__trigger')];
	const dialog = document.querySelector('.raising-concerns dialog');
	const dialogContent = document.querySelector('.raising-concerns dialog .dialog__content');
	const dialogClose = document.querySelector('.raising-concerns dialog .dialog__close');

	dialogClose.addEventListener("click", () => {
		dialog.close();
	});

	triggers.forEach((trigger) => {
		trigger.addEventListener("click", () => {
			dialogContent.innerHTML = trigger.dataset.message;
			dialog.showModal();
		});
	})
}