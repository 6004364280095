const AttachFields = (() => {
    return {
        init: (attachment, submit) => {
            if (attachment) {
                attachment.onchange = (e) => {
                    e.preventDefault();
                    const fileList = Array.from(attachment.files)
                    const file = attachment.files[0],
                        parent = attachment.closest('.c-form-field'),
                        label = parent.querySelector('label'),
                        attachmentMsg = parent.querySelector('.c-form-error-message'),
                        fileListWrapper = parent.querySelector('.c-form-file-name-list'),
                        li = parent.querySelector('li'),
                        input = parent.querySelector('input');

                    let frag = document.createRange().createContextualFragment(`<li data-file=${file.name}>${file.name}
                        <button type="button" class="btn c-remove-link" tabindex="0" aria-label="Remove file ${file.name}" data-link-selected=${file.name}>
                        <span class="material-icons icon-clear">clear</span>Remove</button>
                        </li>`);
                    if (li) {
                        li.remove();
                    }
                    fileListWrapper.appendChild(frag)

                    if (file) {
                        const removeLink = parent.querySelector('button');
                        const attachmentSize = parseInt(parent.getAttribute('data-max-file-size'), 10) || 0,
                            attachmentTypes = (parent.getAttribute('data-allowed-file-types') || '').split(',');

                        label.setAttribute('data-file', attachment.value.replace(/.*(\/|\\)/, ''));

                        if (attachmentTypes.indexOf(file.type) == -1 || file.size > attachmentSize) {
                            submit.disabled = true;
                            parent.classList.add('c-form-error');
                            attachmentMsg.classList.remove('hide');
                        } else {
                            submit.disabled = false;
                            parent.classList.remove('c-form-error');
                            attachmentMsg.classList.add('hide');
                        }

                        removeLink.addEventListener('click', evt => {
                            evt.preventDefault()
                            let wrapper = removeLink.closest('.c-form-field-wrapper');
                            if(wrapper) {
                                let fileInput = wrapper.querySelector('input[type=file]');
                                if(fileInput) {
                                    fileInput.focus();
                                }
                            }
                            removeLink.parentElement.remove();
                            input.value = "";
                            submit.disabled = false;
                            parent.classList.remove('c-form-error');
                            attachmentMsg.classList.add('hide');
                        })
                    }
                };
            }

        }
    }
})();

export default AttachFields;