// add and delete doctor complaint info section
// 2 addition max
const formAddElements = (() => {
    const docSection = document.querySelector('.c-form-complaint-doctor');
    const addBtn = document.querySelector('.u-button-add');
    const addBtnWrapper = document.querySelector('.c-form-button-wrapper-add');
    const allClonesShown = Array.from(document.querySelectorAll('.doctor-clone.show-clone'));
    // the hidden markup waiting to be clone
    const doctor = document.getElementById('ComplaintDoctor');

    // check data-id val, in order to set val to the other number
    function addDoc(){
        const dataset1 = document.querySelector('[data-id="1"]');
        const dataset2 = document.querySelector('[data-id="2"]');
        const dataset3 = document.querySelector('[data-id="3"]');
        const dataset4 = document.querySelector('[data-id="4"]');
        const dataset5 = document.querySelector('[data-id="5"]');
        const dataset6 = document.querySelector('[data-id="6"]');
        const dataset7 = document.querySelector('[data-id="7"]');
        const dataset8 = document.querySelector('[data-id="8"]');
        const dataset9 = document.querySelector('[data-id="9"]');
        const dataset10 = document.querySelector('[data-id="10"]');
        const dataset11 = document.querySelector('[data-id="11"]');
        const dataset12 = document.querySelector('[data-id="12"]');
        const dataset13 = document.querySelector('[data-id="13"]');
        const dataset14 = document.querySelector('[data-id="14"]');
        const dataset15 = document.querySelector('[data-id="15"]');
        const dataset16 = document.querySelector('[data-id="16"]');
        const dataset17 = document.querySelector('[data-id="17"]');
        const dataset18 = document.querySelector('[data-id="18"]');
        const dataset19 = document.querySelector('[data-id="19"]');
        const dataset20 = document.querySelector('[data-id="20"]');
        const dataset21 = document.querySelector('[data-id="21"]');
        const dataset22 = document.querySelector('[data-id="22"]');
        const dataset23 = document.querySelector('[data-id="23"]');
        const dataset24 = document.querySelector('[data-id="24"]');
        if (dataset24) {
            // console.log('dataset23----------------')
            //processNewItem(24, true);
        } else if (dataset23) {
            // console.log('dataset23----------------')
            processNewItem(24, false);
        } else if (dataset22) {
            // console.log('dataset22----------------')
            processNewItem(23, true);
        } else if (dataset21) {
            // console.log('dataset21----------------')
            processNewItem(22, true);
        } else if (dataset20) {
            // console.log('dataset20----------------')
            processNewItem(21, true);
        } else if (dataset19) {
            // console.log('dataset19----------------')
            processNewItem(20, true);
        } else if (dataset18) {
            // console.log('dataset18----------------')
            processNewItem(19, true);
        } else if (dataset17) {
            // console.log('dataset17----------------')
            processNewItem(18, true);
        } else if (dataset16) {
            // console.log('dataset16----------------')
            processNewItem(17, true);
        } else if (dataset15) {
            // console.log('dataset15----------------')
            processNewItem(16, true);
        } else if (dataset14) {
            // console.log('dataset14----------------')
            processNewItem(15, true);
        } else if (dataset13) {
            // console.log('dataset13----------------')
            processNewItem(14, true);
        } else if (dataset12) {
            // console.log('dataset12----------------')
            processNewItem(13, true);
        } else if (dataset11) {
            // console.log('dataset11----------------')
            processNewItem(12, true);
        } else if (dataset10) {
            // console.log('dataset10----------------')
            processNewItem(11, true);
        } else if (dataset9) {
            // console.log('dataset9----------------')
            processNewItem(10, true);
        } else if (dataset8) {
            // console.log('dataset8----------------')
            processNewItem(9, true);
        } else if (dataset7) {
            // console.log('dataset7----------------')
            processNewItem(8, true);
        } else if (dataset6) {
            // console.log('dataset6----------------')
            processNewItem(7, true);
        } else if (dataset5) {
            // console.log('dataset5---------------')
            processNewItem(6, true);
        } else if (dataset4) {
            // console.log('dataset4----------------')
            processNewItem(5, true);
        } else if (dataset3) {
            // console.log('dataset3----------------')
            processNewItem(4, true);
        } else if (dataset2) {
            // console.log('dataset2----------------')
            processNewItem(3, true);
        } else if (dataset1) {
            // console.log('dataset1----------------')
            processNewItem(2, true);
        } else {
            // console.log('dataset else----------------')
            processNewItem(1, true);
        }
    }

    function processNewItem(parmIncrement, isHide) {
        addClone(parmIncrement);
        deleteDoc();
        if (!isHide) {
            addBtnWrapper.classList.add('hide');
        }
    }

    function addClone(incrementVar){
        const clone = doctor.cloneNode(true);
        const firstDelBtn = document.createRange().createContextualFragment(`<div class="c-form-button-wrapper-subtr"><div class="c-form-button-subtr">
    <button class="u-button-remove" type="button" value="Remove Doctor" data-index="1"><span role="img" aria-hidden="true" class="material-icons icon-remove-circle">
    remove_circle
</span>Remove Doctor</button></div>
</div>`);
        clone.id = `ComplaintDoctor${incrementVar}`;
        docSection.insertBefore(clone, addBtnWrapper);
        // after clone, immediately locate the clone and update style, IDs, attributes
        const showCloned = document.getElementById(`ComplaintDoctor${incrementVar}`);
        showCloned.classList.add("show-clone");
        showCloned.setAttribute('data-id', `${incrementVar}`);
        showCloned.setAttribute('tabindex', '0');
        docSection.insertBefore(firstDelBtn, addBtnWrapper);
        // change all the attributes of cloned
        const drName = showCloned.querySelector('.c-input-physician');
        const drNameLab = showCloned.querySelector('.c-input-physician-label');
        const cpsoNum = showCloned.querySelector('.c-input-cpso-ref');
        const cpsoNumbLab = showCloned.querySelector('.c-input-cpso-ref-label');
        const drTel = showCloned.querySelector('.c-input-telephone');
        const drTelLab = showCloned.querySelector('.c-input-telephone-label');
        const drAddress = showCloned.querySelector('.c-physician-address');
        const drAddressLab = showCloned.querySelector('.c-physician-address-label')
        const drDesc = showCloned.querySelector('.c-input-description');
        const drOrder = showCloned.querySelector('.c-input-order');
        // docName
        drNameLab.setAttribute("for", `Complaints[${incrementVar}].DoctorName`);
        drName.setAttribute("name", `Complaints[${incrementVar}].DoctorName`);
        drName.setAttribute("id", `Complaints[${incrementVar}].DoctorName`);
        // cpso#
        cpsoNumbLab.setAttribute("for", `Complaints[${incrementVar}].CPSONum`);
        cpsoNum.setAttribute("name", `Complaints[${incrementVar}].CPSONum`);
        cpsoNum.setAttribute("id", `Complaints[${incrementVar}].CPSONum`);
        // docTelephone
        drTelLab.setAttribute("for", `Complaints[${incrementVar}].Telephone`);
        drTel.setAttribute("name", `Complaints[${incrementVar}].Telephone`);
        drTel.setAttribute("id", `Complaints[${incrementVar}].Telephone`);
        // docAddress
        drAddressLab.setAttribute("for", `Complaints[${incrementVar}].Address`);
        drAddress.setAttribute("name", `Complaints[${incrementVar}].Address`);
        drAddress.setAttribute("id", `Complaints[${incrementVar}].Address`);
        // drDescription
        drDesc.setAttribute("for", "Complaints[".concat(incrementVar, "].Description"));
        drDesc.setAttribute("name", `Complaints[${incrementVar}].Description`);
        drDesc.setAttribute("id", `Complaints[${incrementVar}].Description`);
        // drSortOrder
        drOrder.setAttribute("name", `Complaints[${incrementVar}].SortOrder`);
        drOrder.setAttribute("id", `Complaints[${incrementVar}].SortOrder`);
        drOrder.setAttribute("value", `${incrementVar}`);
        drName.focus();
    }

    function deleteDoc() {
        const isDelBtns = Array.from(document.querySelectorAll('.u-button-remove'));
        isDelBtns.forEach(delBtn => {
            delBtn.addEventListener('click', evt => {
                evt.preventDefault()
                let wrapper = evt.target.closest('.c-form-complaint-doctor');
                if(wrapper){
                    wrapper.querySelector('.c-form-button-wrapper-add button').focus();
                }
                const parentEl = evt.target.parentElement.parentElement;

                // For IE11 - create element.remove() function if not exist
                if (!('remove' in Element.prototype)) {
                    Element.prototype.remove = function() {
                        if (this.parentNode) {
                            this.parentNode.removeChild(this);
                        }
                    };
                }

                if (parentEl) {
                    const prevDocEl = parentEl.previousElementSibling;
                    prevDocEl.remove();
                }
                delBtn.parentElement.remove();
                addBtnWrapper.classList.remove('hide')
            })
        })
    }

    if (doctor) {
        addBtn.addEventListener('click', evt => {
            addDoc()
        })
        deleteDoc()
    }
})();

export default formAddElements;