import $ from 'jquery';

// Scroll page to element
function scrollToElement (el, container, errorFocus) {
  // Send user to top of form
  // console.log(container);
  if (el) {
    let top = $(el);
    $('html,body').animate({scrollTop: top.offset().top - 20}, 300, function (e) {
      if (errorFocus) {
        // Sending a user to a specific field?
        let field = $(document.querySelector(el)).find('input, select').first();
        console.log(field);
        field.tabIndex = 0;
        field.focus();
        field.tabIndex = -1;
      } else {
        // Setting focus on error
        let errorBlock = container.querySelector('.global-error-msg');
        errorBlock.tabIndex = 0;
        errorBlock.focus();
        errorBlock.tabIndex = -1;
      }
    });
  }
}
export default scrollToElement;
