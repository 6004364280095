//including smooth scroll
import $ from 'jquery';


//loop through all the h2s on doc detials page
const docDetails = document.getElementById('docDetails');
const doctorDetailsPrint = document.getElementById('doctorDetailsPrint');

if (docDetails && !doctorDetailsPrint) {
	let headingsList = Array.from(docDetails.querySelectorAll('h2'));
	const stickyList = document.getElementById('stickyMemberInfoList');

	//add ids to them that are the same as their text content

	headingsList.forEach( (heading) => {
		

		//add for each one, create a list element and a tag with the same content, and an href that is hashed at the id
		let li = document.createElement('li');
		let anchor = document.createElement('a');
		let span = document.createElement('span');

		//if this link has a flag in it, ignore the flag text ( in a span)
		let headingContent = heading.innerHTML
		let hasFlag = headingContent.includes('doc-alert');
		if (hasFlag){
			let docAlert = heading.querySelector('.doc-alert');

			anchor.classList.add('flagged');
			// add flag alt text to items in list as well
			span.classList.add('show-for-sr');
			span.innerHTML = docAlert.textContent;

			//also add a flag to the Doctor's full name, and any flagged sections
			let docTitle = document.getElementById('docTitle');

			docTitle.classList.add('flagged');
			docTitle.classList.add('inline');

			const titleSpan = span.cloneNode(true);
			docTitle.appendChild(titleSpan);

			heading.classList.add('flagged');
			heading.classList.add('inline');
		}

		// get just the first bit of text, nothing in the nested code
		const text = $(heading).contents().filter(function() {
			return this.nodeType == Node.TEXT_NODE;
		}).text();

		//create an ID string from the heading text with no spaces
		let idString = text.replace(/\s+/g, '');
		heading.id = idString;

		anchor.textContent = text;
		anchor.appendChild(span);
		
		anchor.href = `#${idString}`;

		li.appendChild(anchor);
		stickyList.appendChild(li);



	})
}

//smoothscroll
// Select all links with hashes
$('.anchor_nav a')
  // Remove links that don't actually link to anything

  .click(function(event) {
    // On-page links

      // Figure out element to scroll to
      var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		//het height of header with or without alert bar
		var headerHeight = $("header#siteheader").height();
      // Does a scroll target exist?
      if (target.length) {
		  // Only prevent default if animation is actually gonna happen
		  var scrollToPosition = $(target).offset().top - headerHeight;
        event.preventDefault();
        console.log(scrollToPosition);
        $('html, body').animate({
          scrollTop: scrollToPosition
        }, 600, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
   //  }
  });
