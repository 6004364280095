const doctorDetailsPrintDialog = () => {

	setTimeout(function(){ window.print(); }, 2000);

};

const doctorDetailsPrint = document.getElementById('doctorDetailsPrint');

if (doctorDetailsPrint) {
	doctorDetailsPrintDialog();
}




