// Validate postal Code
function checkPostalCode (postalCode) {
  if (!postalCode) {
    return false;
  }

  var ca = /^[A-Za-z]\d[A-Za-z]/i;

  if (ca.test(postalCode)) {
    return true;
  }

  return false;
}
export default checkPostalCode;
