import checkPostalCode from './checkPostalCode';

/*
 * Validates General Search criteria
 *
 * @return boolean hasError
 */
function validateGeneralSearch () {
  let container = document.querySelector('#general-search-tab');
  let postalCode = container.querySelector('input.cpso-postal');
  let specialist = container.querySelector('input.cpso-specialist').checked;
  let specialization = container.querySelector('select.cpso-specialization');
  specialization = specialization.options[specialization.selectedIndex].value;
  let active = container.querySelector('input.cpso-regactive').checked;
  let inactive = container.querySelector('input.cpso-reginactive').checked;
  let cpsoNumber = container.querySelector('input.cpso-number').value;
  let hasError = false;


  // CPSO Number Validation
  if (cpsoNumber.length > 0 && cpsoNumber.length < 5) {
    hasError = true;
  }

  // Postal Code Validation
  if (postalCode.value.length > 0) {
    let postalCodeValue = postalCode.value;
    postalCode.value = postalCodeValue.replace(/\s/g, ''); // replace all whitespace & update input

    let validPostalCode = checkPostalCode(postalCode.value);
    if (!validPostalCode) {
      hasError = true;
    }
  }

  // Specialization Validation
  if (specialist) {
    if (specialization === '') {
      hasError = true;
    }
  }

  // Registration Status
  if (!active && !inactive) {
    hasError = true;
  }

  // Concerns
  let btnAdvToggleConcerns = document.querySelector('.doctor-concerns button.toggle-switch');
  let concernsChecked = false;
  if (btnAdvToggleConcerns.classList.contains('active')) {
    // Loop all concerns
    [...container.querySelectorAll('.concerns .horizontal-list input')].forEach(function (concern) {
      if (concern.checked) {
        concernsChecked = true;
      }
    });
    if (!concernsChecked) {
      // All checkboxes are unchecked
      hasError = true;
    }
  }

  return hasError;
};

export default validateGeneralSearch;
