/*###########################################*/
/*###########################################*/
/*######							#########*/
/*######	  CPSO - SITE JS		#########*/
/*######							#########*/
/*###########################################*/
/*###########################################*/

import Forms from "./modules/form";
import AttachFields from "./modules/formAttachment";

jQuery(document).ready(function () {
	/*ALL PAGES*/
	SetupMobileNavigation();
	SetupDesktopNavigation();
	SetupStickies();
	SetupMobileSearchMenu();
	SetupHeaderSearchBarToggle();
	SetupHeaderBrowserDetection();
	SetupDisableLogo();
	SetupFacebookTwitterCarousels();
	SetupAccordionReadMinutes();
	SetupNewsMinutesToRead();
	SetupMobileSideMenu();
	SetupAnchorLinks();
	SetupSearchResults();
	SetupFindADoc();
	SetupCloseAlertMessage();
	SetUpAllAccordions();
	Forms.init();
	AttachFields.init();

	//PRINT BUTTON
	$('a.print_page_btn').click(function (e) {
		e.preventDefault();
		window.print();
    });

    $('a.skiptocontent').click(function (e) {
        var el = $(this).attr('href');
        $(el).attr('tabindex', '-1');
        $(el).focus();
    });

	/*ECA PAGES*/
	SetupAdditionalInformationToggle();
});

/*SETUP DISABLE RIGHT CLICK ON LOGO*/
function SetupDisableLogo() {
	$('.header_logo_container').on('contextmenu', 'img', function(e){
		return false;
	});
}

/*SETUP HEADER BROWSER DETECTION */
function SetupHeaderBrowserDetection() {

	// newUrl = 'http://www.google.com' // this variable is set through backend, near the Head

	let ua = window.navigator.userAgent;
	let msie = ua.indexOf('MSIE ');

	jQuery('.redirect-check').on('click', function (e) {
		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
			e.preventDefault();
			window.location.href = newUrl.toLowerCase();
		}
	});
}

/*SETUP NAVIGATION MENU*/
function SetupDesktopNavigation() {

	var activeNav = {};

	jQuery('#m_nav_btn').on('click', function (e) {
		e.preventDefault();
		$('#m_nav_btn').attr('aria-expanded', 'true');
		jQuery('body,html').addClass('disable_overflow');
		jQuery('.header_navigation_container').addClass('open');

		$(".header_navigation_container").find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').filter(":visible").first().focus();

		$('.header_logo_container')[0].inert = true
		$('.header_mobile_nav_btn_container')[0].inert = true
		$('.header_search_login_container')[0].inert = true
		$('.header_mobile_search_btn_container')[0].inert = true
		// $('.header_navigation_container')[0].inert = true
		$('.header_mobile_search_overlay')[0].inert = true
		$('#content')[0].inert = true
		$('footer')[0].inert = true
		$('.skiptocontent')[0].inert = true
		$('.back_to_top_button')[0].inert = true
	});

	jQuery('.mobile_navigation_close').on('click', function (e) {
		e.preventDefault();
		jQuery('body,html').removeClass('disable_overflow');
		jQuery('.header_navigation_container').removeClass('open');

		$('.header_logo_container')[0].inert = false
		$('.header_mobile_nav_btn_container')[0].inert = false
		$('.header_search_login_container')[0].inert = false
		$('.header_mobile_search_btn_container')[0].inert = false
		// $('.header_navigation_container')[0].inert = false
		$('.header_mobile_search_overlay')[0].inert = false
		$('#content')[0].inert = false
		$('footer')[0].inert = false
		$('.skiptocontent')[0].inert = false
		$('.back_to_top_button')[0].inert = false

		if ($(activeNav.el)) {
			$(activeNav.toggle).attr("aria-expanded", "false");
			$(activeNav.el).removeClass('open');

			if ($(window).width() <= 850) {
				$('.header_navigation_container').removeClass('disable_overflow');
				$(activeNav.el).find(".navigation_megamenu_container").removeClass('open');
			}

			activeNav = {};
		}

		$('#m_nav_btn').focus();
		$('#m_nav_btn').attr('aria-expanded', 'false');
	});

	jQuery('.header_navigation_container nav ul li .nav-toggle').on('click', function (e) {
		e.preventDefault();

		if ($(this).parent("li").hasClass("open")) {
			$(activeNav.toggle).attr("aria-expanded", "false");
			$(activeNav.el).removeClass('open');
			activeNav = {};
		} else {
			activeNav.toggle = $(this);
			activeNav.el = $(activeNav.toggle).parent("li");
			activeNav.group = $(activeNav.el).find(".navigation_megamenu_container");

			$(activeNav.toggle).attr("aria-expanded", "true");
			$(activeNav.el).addClass('open');

			if ($(window).width() <= 850) {
				$('.header_navigation_container').addClass('disable_overflow');
				$(activeNav.group).addClass('open');
				$(activeNav.group).find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').filter(":visible").first().focus();
			}

			activeNav.children = $(activeNav.group).find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').filter(":visible");
		}
	});

	jQuery('.header_navigation_container nav ul li.has-child').on('mouseenter', function (e) {
		e.preventDefault();

		if ($(window).width() <= 850) {
			return
		} else {
			activeNav.toggle = $(this).find('.nav-toggle');
			activeNav.el = $(this);
			activeNav.group = $(activeNav.el).find(".navigation_megamenu_container");

			$(activeNav.toggle).attr("aria-expanded", "true");
			$(activeNav.el).addClass('open');

			activeNav.children = $(activeNav.group).find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').filter(":visible");
		}

	});

	jQuery('.header_navigation_container nav ul li.has-child').on('mouseleave', function (e) {
		if ($(window).width() <= 850) {
			return
		} else {
			if ($(this).hasClass("open")) {
				$(activeNav.toggle).attr("aria-expanded", "false");
				$(activeNav.el).removeClass('open');
				activeNav = {};
			}
		}
	});

	// MOBILE BAKC BUTTON
    jQuery('.mobile_navigation_back').on('click', function (e) {
		$(activeNav.toggle).attr("aria-expanded", "false");
		$(activeNav.el).removeClass('open');

		$('.header_navigation_container').removeClass('disable_overflow');
		$(activeNav.group).removeClass('open');

		$(activeNav.toggle).focus();
		activeNav = {};

    });

	// CLOSE NAV ON ESC PRESS IF OPEN
    jQuery(document).on('keyup', function (e) {
		if (e.keyCode === 27) {
			if (activeNav.el) {
				$(activeNav.toggle).attr("aria-expanded", "false");
				$(activeNav.el).removeClass('open');

				if ($(document.activeElement).is(":hidden") || $(window).width() <= 850) {
					$(activeNav.toggle).focus();
				}

				if ($(window).width() <= 850) {
					$('.header_navigation_container').removeClass('disable_overflow');
					$(activeNav.group).removeClass('open');
				}

				activeNav = {};
			} else if (!activeNav.el && $('.header_navigation_container').is('.open') && $(window).width() <= 850) {
				$('.mobile_navigation_close').click();
			}
		}
    });

	// CLOSE NAV FROM TABBING OUT
    $(".header_navigation_container nav ul li").on('focusout', function (e) {
		if ($(window).width() <= 850) {
			return;
		}
		if (activeNav.children && !activeNav.el[0].contains(e.relatedTarget)) {
			$(activeNav.toggle).attr("aria-expanded", "false");
			$(activeNav.el).removeClass('open');
			activeNav = {};
		}
     });

	 // SKIP CONTACT REPEATED
    $('.navigation_megamenu_container a.skippastcontent').click(function (e) {
        var el = $(this).attr('href');
        $(el).attr('tabindex', '-1');
        if ($(el).find('a').length > 0) {
            $(el).find('a').first().focus();
        } else if ($(el).children().length > 0) {
            $(el).focus();
        } else {
			$(activeNav.toggle).attr("aria-expanded", "false");
			$(activeNav.el).removeClass('open');
			$(activeNav.el).focus();
			activeNav = {};
        }
    });

}

/*SETUP MOBILE NAVIGATION MENU*/
function SetupMobileNavigation() {
	// jQuery('#m_nav_btn').on('click', function (e) {
	// 	e.preventDefault();
	// 	jQuery('body,html').addClass('disable_overflow');
	// 	jQuery('.header_navigation_container').addClass('open');
	// });

	// jQuery('.mobile_navigation_close').on('click', function (e) {
	// 	e.preventDefault();
	// 	jQuery('body,html').removeClass('disable_overflow');
	// 	jQuery('.header_navigation_container').removeClass('open');
	// 	if ($(activeNav.el)) {
	// 		$(activeNav.toggle).attr("aria-expanded", "false");
	// 		$(activeNav.el).removeClass('open');

	// 		if ($(window).width() <= 850) {
	// 			$('.header_navigation_container').removeClass('disable_overflow');
	// 			$(activeNav.el).find(".navigation_megamenu_container").removeClass('open');
	// 		}

	// 		activeNav = {};
	// 	}
	// 	$('.mobile_navigation_close').focus();
	// });

// 	jQuery('.show_sublevel').on('click', function (e) {
// 		e.preventDefault();
// 		jQuery('.header_navigation_container').addClass('disable_overflow');
// 		jQuery('.navigation_megamenu_container', jQuery(this).parent()).addClass('open');
// 	});

// 	jQuery('.mobile_navigation_back').on('click', function (e) {
// 		e.preventDefault();
// 		jQuery('.header_navigation_container').removeClass('disable_overflow');
// 		jQuery(this).parent().parent().parent().removeClass('open');
// 	});

}

/*SETUP STICKY SIDE NAV*/
function SetupStickies() {

	//LEFT COLUMN ON CONTENT PAGES
	if (jQuery(".sticky_nav").length) {

		if (jQuery(".sticky_nav").outerHeight() < jQuery('.two_column_sticky_content_container .right_column').outerHeight()) {
			jQuery(".sticky_nav").stick_in_parent({
				offset_top: jQuery('#siteheader').outerHeight() + 40
			}).on('sticky_kit:bottom', function (e) {
				jQuery(this).parent().css('position', 'static');
			}).on('sticky_kit:unbottom', function (e) {
				jQuery(this).parent().css('position', 'relative');
			});
		} else {
			jQuery('.two_column_sticky_content_container .left_column_sticky_nav').css('position', 'static');
		}

	}

	//BACK TO TOP
	jQuery('.back_to_top_button').on('click', function (e) {
		e.preventDefault();
		jQuery("html, body").animate({ scrollTop: 0 }, 250);
	});
}

/*SETUP MOBILE SEARCH MENU*/
function SetupMobileSearchMenu()
{
	jQuery('#m_search_btn').on('click', function (e) {
		e.preventDefault();
		jQuery('body,html').addClass('disable_overflow');
		jQuery('.header_mobile_search_overlay').addClass('open');

		$('.header_logo_container')[0].inert = true
		$('.header_mobile_nav_btn_container')[0].inert = true
		$('.header_search_login_container')[0].inert = true
		$('.header_mobile_search_btn_container')[0].inert = true
		$('.header_navigation_container')[0].inert = true
		// $('.header_mobile_search_overlay')[0].inert = true
		$('#content')[0].inert = true
		$('footer')[0].inert = true
		$('.skiptocontent')[0].inert = true

		if($('.back_to_top_button')[0]) {
			$('.back_to_top_button')[0].inert = true
		}

		jQuery('#m_search_btn').attr('aria-expanded', 'true');
		$('.header_mobile_search_overlay').find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').filter(":visible").first().focus();
	});
	jQuery('.mobile_search_menu_close').on('click', function (e) {
		e.preventDefault();
		jQuery('body,html').removeClass('disable_overflow');
		jQuery('.header_mobile_search_overlay').removeClass('open');

		$('.header_logo_container')[0].inert = false
		$('.header_mobile_nav_btn_container')[0].inert = false
		$('.header_search_login_container')[0].inert = false
		$('.header_mobile_search_btn_container')[0].inert = false
		$('.header_navigation_container')[0].inert = false
		// $('.header_mobile_search_overlay')[0].inert = false
		$('#content')[0].inert = false
		$('footer')[0].inert = false
		$('.skiptocontent')[0].inert = false
		if($('.back_to_top_button')[0]) {
			$('.back_to_top_button')[0].inert = true
		}

		jQuery('#m_search_btn').focus();
		jQuery('#m_search_btn').attr('aria-expanded', 'false');
	});
	jQuery('.header_mobile_search_overlay').on('keyup', function (e) {
		if (e.keyCode === 27) {
			e.preventDefault();
			jQuery('.mobile_search_menu_close').click();
		}
	});
}

/*SETUP HEADER SEARCH BAR TOGGLE*/
function SetupHeaderSearchBarToggle() {
	jQuery('.header_search_container .search_btn').on('click', function (e) {
		e.preventDefault();
		if (jQuery('.header_search_container').hasClass('open'))
		{
			jQuery('.header_search_container').removeClass('open');
			jQuery('.searchboxcontainer').animate({
				width: "0px"
			}, 200, function () {
                jQuery("#btnHeaderSearch").attr("aria-label", "Open header site search bar");

            });
		} else {
			jQuery('.header_search_container').addClass('open');
			jQuery('.searchboxcontainer').animate({
				width: "100%"
			}, 200, function () {
                jQuery("#btnHeaderSearch").attr("aria-label", "Close header site search bar");
                jQuery('.searchboxcontainer input[type=text]').focus();
            });
		}
	});
}

function SetupFacebookTwitterCarousels() {

	if (jQuery('.tweet_facebook_feeds_outer_container').length)
	{
		jQuery('.carousel').slick({
			autoplay:false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false
		});
		jQuery('.carousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
			console.log('test')
			$(this).find('.slick-active').focus();
		});
	}

}


/*SETUP MINS TO READ ACCORDION*/
function SetupAccordionReadMinutes()
{
	jQuery('.accordion.show_mins_to_read .card').each(function () {
		var minstoread = CalcMinutesToRead(jQuery('.card-body', jQuery(this)));
		jQuery('.card-header', jQuery(this)).append('<div class="minstoread">' + minstoread + "</div>");
	});
}


var words_per_minute = 200;
function CalcMinutesToRead(elem) {
	var wordCount = jQuery(elem).text().replace(/[^\w ]/g, "").split(/\s+/).length;
	var minstoread = 0;
	if (wordCount > 200)
	minstoread = Math.round(wordCount / words_per_minute) + ' min read';
	else
	minstoread = '1 min read';
	return minstoread;
}

/*SETUP MOBILE SIDE MENU*/
function SetupMobileSideMenu()
{

	if (jQuery('.left_column_sticky_nav.mobile_collapse .mobile_submenu_toggle button').length)
	{
		jQuery('.left_column_sticky_nav.mobile_collapse .mobile_submenu_toggle button').on('click', function (e) {
			e.preventDefault();
			var ulmenu = jQuery('ul', jQuery(this).parent().parent());

			jQuery(this).toggleClass('open');
			jQuery(this).hasClass('open') ? jQuery(this).attr('aria-expanded', 'true') : jQuery(this).attr('aria-expanded', 'false');

			if (jQuery(ulmenu).hasClass('open'))
				jQuery(ulmenu).slideUp(200, function () { jQuery(ulmenu).attr('style', ''); jQuery(ulmenu).removeClass('open'); });
			else
				jQuery(ulmenu).slideDown(200, function () { jQuery(ulmenu).attr('style', ''); jQuery(ulmenu).addClass('open'); });


		});
	}

}



/*SETUP ADDITIONAL INFORMATION TOGGLE FOR DOCTOR DETAILS PAGES*/
function SetupAdditionalInformationToggle()
{
	jQuery('.additional-information-toggle').on('click', function (e) {
		e.preventDefault();
		jQuery('#'+jQuery(this).attr('aria-controls')).slideToggle(250);
		jQuery(this).toggleClass('open');
		jQuery(this).attr('aria-expanded') == 'true' ? jQuery(this).attr('aria-expanded', 'false') : jQuery(this).attr('aria-expanded', 'true');
	});
}


/*SETUP CONTENT PAGE ANCHOR LINKS*/
function SetupAnchorLinks()
{
	if (jQuery('.anchor_links_container').length)
	{
		var haslinks = false;
        // At implementation time, there is only on anchor link section on the page, so we can hardcode the id
        const anchorLinksContainerGuid = crypto.randomUUID();
		jQuery('#content [data-anchor-link]').each(function () {
			if (jQuery(this).attr('id'))
			{
				jQuery('.anchor_links_container').append('<a href="#' + jQuery(this).attr('id') + '">' + jQuery(this).attr('data-anchor-link') + '</a>');
				haslinks = true;
			}
		});

		if (haslinks)
		{
			var onThisPage = __SiteLanguage == 'fr' ? 'SUR CETTE PAGE:' : 'ON THIS PAGE:';
			jQuery('.anchor_links_container').prepend('<h2 id="' + anchorLinksContainerGuid + '">' + onThisPage + '</h2>');
            jQuery('.anchor_links_container').attr('aria-labelledby', anchorLinksContainerGuid);
			jQuery('.anchor_links_container').slideDown(500);
		}

		jQuery('.anchor_links_container a').on('click', function (e) {
			e.preventDefault();
			var el = jQuery(jQuery(this).attr('href'));
			jQuery('html, body').animate({
				scrollTop: jQuery(el).offset().top - 70
			}, 500);
			jQuery(el).attr('tabindex', '-1');
			jQuery(el).focus();
		});
	}
}

/*SEARCH RESULTS PAGE*/
function SetupSearchResults()
{
	if (jQuery('#txtHeaderSearch').length)
	{
		jQuery('#txtHeaderSearch').on('keyup', function () {
			if (event.keyCode == 13)
				Search(this, jQuery('#txtHeaderSearch').val(), '/search');
		});
	}


	if (jQuery('#txtSearchMobile').length) {

		jQuery('#txtSearchMobile').on('keyup', function () {
			if (event.keyCode == 13)
				Search(this, jQuery('#txtSearchMobile').val(), '/search');
		});

		jQuery('#btnMobileSearch').on('click', function (e) {
			e.preventDefault();
			Search(this, jQuery('#txtSearchMobile').val(), '/search');
		});

	}



	if (jQuery('.search_results_search_bar').length)
	{
		jQuery('#btnSearchResultsSearch').on('click', function (e) {
			e.preventDefault();
			Search(this, jQuery('#txtSearchResultsSearchBar').val(), window.location.href);
		});

		jQuery('#txtSearchResultsSearchBar').on('keyup', function () {
			if (event.keyCode == 13)
				jQuery('#btnSearchResultsSearch').click();
		});
	}
}

//SEARCH
function Search(buttonortextbox, searchtext, searchurl)
{
	var regex = /(<([^>]+)>)/ig;
	var searchtextsanitized = searchtext.replace(regex, "");
	regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
	searchtextsanitized = encodeURIComponent(searchtext.replace(regex, ""));

	var url = searchurl;
	if (url.indexOf("?") > 0) {
		url = url.substring(0, url.indexOf("?"));
	}
	url += "?searchtext=" + searchtextsanitized;

	jQuery(buttonortextbox).unbind('click');
	jQuery(buttonortextbox).unbind('keyup');

	window.location.replace(url);
}

/*FIND A DOC HOMEPAGE*/
function SetupFindADoc() {
	if (jQuery('.find_a_doc_hover_outer_container').length)
	{
		jQuery('.phys_submit a').on('click', function (e) {
			e.preventDefault();

			var url = findadochomesection_url; //defined inside home index view
			var searchtext = "";
			var searchmode = "";
			var lastname = jQuery('#txtPhysLastName').val().trim();
			var cpsonum = jQuery('#txtPhysCPSONum').val().trim();
			if (lastname.length > 0 && cpsonum.length < 1) {
				searchtext = lastname;
				searchmode = "name";
			} else if (cpsonum.length > 0 && lastname.length < 1) {
				searchtext = cpsonum;
				searchmode = "num";
			} else if (cpsonum.length > 0 && lastname.length > 0) {
				searchtext = cpsonum;
				searchmode = "num";
			}

			if (searchtext == "") {
				var errorMsg = $('.find_a_doc_hover_outer_container').find('.global-error-msg');
				$(errorMsg).slideDown();
				$('#txtPhysLastName').attr('aria-describedby', 'fadError');
				$('#txtPhysCPSONum').attr('aria-describedby', 'fadError');
				$('#txtPhysLastName').attr('aria-invalid', 'true');
				$('#txtPhysCPSONum').attr('aria-invalid', 'true');

				$(errorMsg).focus();
				return;
			}


			var regex = /(<([^>]+)>)/ig;
			var searchtextsanitized = searchtext.replace(regex, "");
			regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
			searchtextsanitized = encodeURIComponent(searchtext.replace(regex, ""));
			window.location.replace(url + "?type=" + searchmode + "&term=" + searchtextsanitized);
		});

		$(document).on('click', '.global-error-msg p a', function (e) {
			var urlTarget = $(this).attr('href');
			// scrollToElement(urlTarget, '', true);

			var top = $('.find_a_doc_hover_outer_container').find('.global-error-msg')

			console.log('hello');
			$('html,body').animate({scrollTop: top.offset().top - 20}, 300, function (e) {
				$(urlTarget).focus();
			});
			e.preventDefault();
		  });

	}
}


///////////////
//OTHER FUNCTIONS
///////////////

//CLOSE ALERT MESSAGE
function SetupCloseAlertMessage() {
	jQuery('[data-close-alert]').on('click', function (e) {
		e.preventDefault();

		console.log('hello')

		jQuery('.alert_message_container').slideUp(250);
		jQuery('#content').removeClass('alert_message_shown');
		Cookies.set('HideAlertMessage', 'true', { expires: 1 });
	});
}

function SetUpAllAccordions(accid) {
	jQuery('[data-accordion-expand-all]').on('click', function (e) {
		e.preventDefault();
		var accid = $(this).attr('data-accordion-expand-all');
		ExpandAllAccordions(accid)
	});
	jQuery('[data-accordion-close-all]').on('click', function (e) {
		e.preventDefault();
		var accid = $(this).attr('data-accordion-close-all');
		CloseAllAccordions(accid)
	});
}

//EXPAND ALL ACCORDION ITEMS
function ExpandAllAccordions(accid)
{
	if (jQuery(accid).length)
	{
		var status = $(accid+'_status');
		console.log(status);
		$(status).text("Accordions expanded");
		jQuery(accid + ' .collapse').each(function () {
			var toggle = jQuery(this).parent().find('button');
			jQuery(this).addClass('show');
			jQuery(toggle).attr('aria-expanded', 'true');
		});
	}

}

//CLOSE ALL ACCORDION ITEMS
function CloseAllAccordions(accid) {
	if (jQuery(accid).length) {
		var status = $(accid+'_status');
		console.log(status);
		$(status).text("Accordions collapsed");
		jQuery(accid + ' .collapse').each(function () {
			jQuery(this).removeClass('show');
			var toggle = jQuery(this).parent().find('button');
			jQuery(toggle).attr('aria-expanded', 'false');
		});
	}

}

//SCROLLED INTO VIEW
function isScrolledIntoView(elem) {
	var docViewTop = jQuery(window).scrollTop();
	var docViewBottom = docViewTop + jQuery(window).height();
	var elemTop = jQuery(elem).offset().top;
	var elemBottom = elemTop + jQuery(elem).height();
	return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

/*SETUP READING PROGRESS BAR FOR NEWS POSTINGS*/
// function SetupPostReadingProgressBar() {

// 	if (jQuery('.news_navigation_progress_share_container').length)
// 	{

// 		var s = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
// 		var body = document.body;
// 		var html = document.documentElement;
// 		var d = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
// 		var c = window.innerHeight + 450;
// 		var position = (s / (d - c)) * 100;
// 		if (position > 100 || position < 0)
// 			position = 100;

// 		document.getElementById('news_reading_progress').setAttribute('style', 'width: ' + position + '%');

// 	}
// }


//WINDOW RESIZE SCROLL
jQuery(window).on('resize scroll', function () {
	if (isScrolledIntoView(jQuery('.skiptocontent')))
		jQuery('.back_to_top_button').fadeOut(250);
	else
		jQuery('.back_to_top_button').fadeIn(250);

});


//ANCHOR OFFSET
(function (document, history, location) {
	var HISTORY_SUPPORT = !!(history && history.pushState);

	var anchorScrolls = {
		ANCHOR_REGEX: /^#[^ ]+$/,
		OFFSET_HEIGHT_PX: 75,

		/**
		 * Establish events, and fix initial scroll position if a hash is provided.
		 */
		init: function () {
			this.scrollToCurrent();
			window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
			document.body.addEventListener('click', this.delegateAnchors.bind(this));
		},


		getFixedOffset: function () {
			return this.OFFSET_HEIGHT_PX;
		},


		scrollIfAnchor: function (href, pushToHistory) {
			var match, rect, anchorOffset;

			if (!this.ANCHOR_REGEX.test(href)) {
				return false;
			}

			match = document.getElementById(href.slice(1));

			if (match) {
				rect = match.getBoundingClientRect();
				anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
				window.scrollTo(window.pageXOffset, anchorOffset);

				// Add the state to history as-per normal anchor links
				if (HISTORY_SUPPORT && pushToHistory) {
					history.pushState({}, document.title, location.pathname + href);
				}
			}

			return !!match;
		},


		scrollToCurrent: function () {
			this.scrollIfAnchor(window.location.hash);
		},


		delegateAnchors: function (e) {
			var elem = e.target;

			if (
				elem.nodeName === 'A' &&
				this.scrollIfAnchor(elem.getAttribute('href'), true)
			) {
				e.preventDefault();
			}
		}
	};

	window.addEventListener(
		'DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls)
	);
})(window.document, window.history, window.location);

// MINS TO READ NEWS ARTICLE
function SetupNewsMinutesToRead() {
	var minstoreadarticle = CalcMinutesToRead('.content_block_container');
	if (minstoreadarticle) {
		$('.news_mins_read').text(minstoreadarticle);
	}
}

// HEADER SHIM
var headerElem = document.getElementById("siteheader");
var headerShim = document.querySelector(".header-shim");
var mainElem = document.querySelector("main");

function resizeHeaderShim() {
	var headerHeight = headerElem.offsetHeight;
	headerShim.style.height = headerHeight + "px";
    mainElem.style.scrollPaddingTop = (headerHeight * 2) + "px";
}
resizeHeaderShim();

window.addEventListener('resize', function() {
	resizeHeaderShim();
});